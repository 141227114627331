const tabs = {
    get_started: 'Başla',
    dashboard: 'Gösterge Paneli',
    applications: 'Uygulamalar',
    api_resources: 'API Kaynakları',
    sign_in_experience: 'Oturum Açma Deneyimi',
    connectors: 'Connectors',
    enterprise_sso: 'Kurumsal SSO',
    security: '安全',
    webhooks: 'Webhooks',
    organizations: 'Organizasyonlar',
    users: 'Kullanıcı Yönetimi',
    audit_logs: 'Denetim Kayıtları',
    roles: 'Roller',
    docs: 'Dökümanlar',
    tenant_settings: 'Ayarlar',
    mfa: 'Çoklu faktörlü kimlik doğrulama',
    customize_jwt: 'Özel JWT',
    signing_keys: 'İmza anahtarları',
    organization_template: 'Kuruluş şablonu',
};
export default Object.freeze(tabs);
